@import '../../styles/colors.scss';

$minWidth: 20px;
$maxWidth: 280px;
$transitionTime: .15s;
$navItemPadding: 10px;
$navIconHeight: 40px;

$hoverBackground:  #FFFFFF0C 0% 0% no-repeat padding-box;

//2*padding + icon height
$navItemHeight: 65px;

.navRoot {
  display: flex;
  flex: 1;
 // overflow: hidden;
}

.iconWrapper {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiIcon-root {
    color:#ffffffea
  }
}

img {
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.navItem__text {
  padding-left: 20px;
  flex: 1;
  text-align: left;
  font: Medium 14px/19px Roboto;
  letter-spacing: 0.24px;
  color: #FFFFFF;
  opacity: 1;
  position: static;
  text-decoration: none;
}

.navItem__image{
  width: $navIconHeight;
  height: $navIconHeight;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navItem, .navItem:link, .navItem:visited {
  display: flex;
  align-items: center;
  height: $navItemHeight;
  padding: 0 10px 0 10px;
  transition: flex $transitionTime;
  overflow: hidden;
  user-select: none;
  text-decoration: none;
}

.navItem:hover {
  background: $hoverBackground;
}

.navItem.toggle {
  background-color: $color-darkRed;
  overflow: visible;
  cursor: pointer;

  .MuiIcon-root {
    color: white;
  }

  .navItem__logo {
    margin-left: 70px;
    position: absolute;
  }

  .hidden {
    display: none;
  }

  img {
    height: $navIconHeight;
  }
}

.navItem.selected {
  background: $hoverBackground;

  .MuiIcon-root {
    opacity: 1;
  }

  .navItem__text {
    opacity: 1;
  }
}

.navContainer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  transition: min-width $transitionTime;
  min-width: $minWidth;
  background-color: $color-darkRed;
}

.navContainer.open {
  min-width: $maxWidth;
  transition: min-width $transitionTime;

  .toggle .navItem__text {
    opacity: 0.8;
  }
}

.toolBar {
  width: 100%;
  top: 0;
  height: $navItemHeight;
  background-color: white;
  position:fixed;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12);
  z-index: 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .language-container, .setting-container{
    margin-right: 10px;
  }
}

.navContentContainer {
  display: flex;
  width: calc(100% - 70px);
  flex: 1;
  margin-left: 70px;
  margin-top: 40px;
  transition: margin $transitionTime;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  padding: 30px;

}

.navContentContainer.open {
  margin-left: $maxWidth;
  width: calc(100% - 250px);
}

.navContentContainer__content {
  max-width: 90vw;
  min-width: 720px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.navItem {
  .toggle .icon {
    font-size: 1.5rem;
  }

  &:hover {
    .MuiIcon-root, .navItem__text {
      opacity: 1;
    }
  }

  &:hover .toggle .icon{
    font-size: 1.5rem;
  }

  .toggle:hover .navItem__image .icon {
    font-size: 1.5rem;
  }

  &:hover .icon{
    font-size: 1.7rem;
  }

  .icon {
    transition: 0.15s;
    font-size: 1.2rem;
  }
  .MuiIcon-root, .navItem__text {
    opacity: 0.8;
  }
}

.renderNav {
  display: flex;
  flexDirection: row;
  visibillity: hidden;
}

