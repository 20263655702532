@import '../../shared/styles/colors.scss';

$spacing: 8px;
$tableRowHeight: 50px;
$tableHeadFontSize: 1rem;

.root {
  width: 100%;
  padding: 0 $spacing;
}

.main{
  margin-left: 70px;
}
