@import '../../styles/colors.scss';

$primaryColor: #800000;
$secondaryColor: #1F1A17;

.dashboard-hover {
  .MuiListItemText-primary {
    color: $secondaryColor;

    &:hover {
      color: $primaryColor;
    }
  }
}


.icon-clear_all {
  transform: scaleX(-1)
}

.dashboard-icon {
  font-size: 24px;
}

