$color-darkRed: #800000;
$color-lightRed: #8D1A1A;
$color-black: #1F1A17;
$color-darkGrey: #605C5C;
$color-lightGrey: #F5F5F5;

$tableRowDark: $color-darkGrey;
$tableRowLight: white;

$primaryColor: $color-darkRed;
$secondaryColor: $color-black;
